//////////////////////////////
// NewsLetter Form 
/////////////////////////////

.siteFooterSubscribe {
  background-color: $ywca-purple;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 0;
  
  @include media-breakpoint-up(lg){
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.siteFooterSubscribe__btnClose {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  background-color: transparent;
  color: $white;

  @include media-breakpoint-up(lg){
    top: 4px;
  }

  &:focus {
    outline: 0;
  }
}

.siteFooterSubscribe__success {
  @include media-breakpoint-up(lg){
    padding: 12px;
  }
  p {
    text-align: center;
    color: $white;
  }
}

.siteFooterSubscribe__form {
  @include media-breakpoint-up(lg){
    display: flex;
    flex-wrap: nowrap;
  }
}

.siteFooterSubscribe__col1 {
  @include media-breakpoint-up(lg){
    flex: 0 0 40%;
    padding-left: 30px;
  }
}

.siteFooterSubscribe__col2 {
  @include media-breakpoint-up(lg){
    flex: 0 0 30%;
    padding-left: 30px;
  }
}

.siteFooterSubscribe__col3 {
  @include media-breakpoint-up(lg){
    flex: 0 0 30%;
    padding-left: 30px;
  }
}

.form-control-footer {
  background-color: $ywca-purple;
  color: $white;
  @include font-bold;

  &:focus {
    color: $white;
    background-color: $ywca-purple;
    border-color: $white;
  }

  &::placeholder {
    color: $white;
  }
}

.custom-select-footer {
  color: $white;
  @include font-bold;
  background: $ywca-purple url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMS45Nzg0IDcuNzA3MDZMMTIuMTM1NiAxNy41NDk4TDIuMjkyOTcgNy43MDcwNUwzLjcwNzE5IDYuMjkyODVMMTIuMTM1NiAxNC43MjE0TDIwLjU2NDIgNi4yOTI4NUwyMS45Nzg0IDcuNzA3MDZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) no-repeat right 2.4rem center/24px 24px;
  &:focus {
    color: $white;
    background-color: $ywca-purple;
    border-color: $white;
  }
  &:focus::-ms-value {
    color: $white;
    background-color: $ywca-purple;
  }
}

.newsletter-btn {
  @include font-bold;
  display: block;
  width: 100%;

  @include media-breakpoint-up(lg){
    display: inline-block;
    width: 80%;
  }

  &:focus {
    box-shadow: none;
  }
}