.siteFooterBottom__container {
  max-width: 100%;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 0fr 0fr;
  grid-template-areas: 
    "left" 
    "center" 
    "right";

  @include media-breakpoint-up(md){
    max-width: 620px;
    padding-top: 56px;
  }

  @include media-breakpoint-up(lg){
    padding-top: 82px;
    max-width: 1348px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 20px;
    grid-template-areas: 
      "left center right";
  }

  @include media-breakpoint-up(xl){
    grid-template-columns: 1fr 468px 1fr;
  }
}

.siteFooterBottom__left { 
  grid-area: left; 
}

.siteFooterBottom__center { 
  grid-area: center; 
}

.siteFooterBottom__right { 
  grid-area: right; 
}


.siteFooterLeft__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include media-breakpoint-up(lg){
    padding-right: 68px;
  }
}

.siteFooterLeft__statement {
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 24px;

  @include media-breakpoint-up(md){
    flex: 0 0 55%;
    max-width: 55%;
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg){
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 22px;
  }
}

.siteFooterLeft__socialMedia {
  flex: 0 0 100%;
  max-width: 100%;

  @include media-breakpoint-up(md){
    flex: 0 0 45%;
    max-width: 45%;
  }

  @include media-breakpoint-up(lg){
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.siteFooter__statement {
  font-size: 1.4rem;
  line-height: 1.714285;
  color: $white;
}

.siteFooter__socialMedia {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  @include media-breakpoint-up(md){
    justify-content: flex-end;
  }

  @include media-breakpoint-up(lg){
    justify-content: flex-start;
  }
}

.siteFooter__socialMedia__item {
  display: inline-flex;
  a {
    display: block;
    color: $white;

    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }

    svg {
      vertical-align: top;
    }
  }
}

.siteFooter__socialMedia__item + .siteFooter__socialMedia__item {
  margin-left: 20px;
}

.siteFooterGraphic {
  display: none;
  @include media-breakpoint-up(lg){
    display: block;
  }
}

.siteFooterBottom__right__container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-top: 50px;
  @include media-breakpoint-up(lg){
    padding-left: 40px;
    padding-top: 0;
    height: 100%;
  }
  @include media-breakpoint-up(xl){
    padding-left: 90px;
  }
}

.siteFooterNav {
  flex: 1 0 auto;
  @include media-breakpoint-up(lg){
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
  }
}

.siteFooterCompany {
  flex: 0 0 auto;
}

.siteFooterNav__top,
.siteFooterNav__bottom {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -10px;
  padding: 0;

  @include media-breakpoint-up(lg){
    flex: 0 0 50%;
    max-width: 50%;
    align-items: flex-start;
    margin: 0;
  }

  li {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;

    @include media-breakpoint-up(md){
      flex: 0 0 25%;
      max-width: 25%;
      padding-bottom: 40px;
    }

    @include media-breakpoint-up(lg){
      flex: 0 0 100%;
      max-width: 100%;
      padding-bottom: 24px;
      padding-left: 0;
    }
  }

  a {
    font-size: 1.4rem;
    color: $white;
    @include font-medium;
  }

  a.footerNav-sub {
    @include font-regular;
  }
}

.siteFooterNav__top {
  @include media-breakpoint-up(lg){
    order: 1;
  }
}


.siteFooterCompany {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 60px;

  @include media-breakpoint-up(md){
    padding-top: 38px;
  }

  @include media-breakpoint-up(lg){
    padding-top: 20px;
    flex-direction: row;
    justify-content: space-between;
  }
}

.siteFooterCompany__item {
  color: $white;
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
  width: 100%;

  @include media-breakpoint-up(lg){
    width: auto;
  }
}

a.siteFooterCompany__item {
  &:hover {
    color: $white;
  }
}