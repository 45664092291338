.siteFooterTop__container {
  max-width: 100%;
  padding-left: 34px;
  padding-right: 34px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 0fr;
  row-gap: 32px;
  grid-template-areas: 
    "siteFooterTop__colLeft" 
    "siteFooterTop__colRight";

  @include media-breakpoint-up(md){
    max-width: 620px;
    row-gap: 46px;
  }

  @include media-breakpoint-up(lg){
    max-width: 1348px;
    grid-template-columns: 98px 1fr;
    grid-template-rows: 1fr;
    column-gap: 20px;
    row-gap: 0;
    grid-template-areas: 
      "siteFooterTop__colLeft siteFooterTop__colRight";
  }

  @include media-breakpoint-up(xl){
    grid-template-columns: 305px 1fr;
  }
}

.siteFooterTop__colLeft { 
  position: relative;
  grid-area: siteFooterTop__colLeft;
  display: flex;
  align-items: flex-start;
}

.siteFooterTop__colRight {
  position: relative;
  grid-area: siteFooterTop__colRight;
}

.siteFooterTop__logo {
  display: block;
  width: 54px;
}

.siteFooterTop__contentRight {
  display: flex;
  width: 100%;
  @include media-breakpoint-up(lg){
    justify-content: flex-end;
  }
}

.siteFooterTop__btnDonate {
  @include font-bold;
  border-color: $white;
}

.siteFooterTop__btnRegister {
  display: block;
  @include font-bold;
  flex: 1 0 auto;
  @include media-breakpoint-up(md){
    margin-left: 34px;
  }
  @include media-breakpoint-up(lg){
    display: inline-block;
    flex: 0 0 auto;
    margin-left: 20px;
  }

  &:focus {
    box-shadow: none;
  }
}



