$gridImgGradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 43.25%, rgba(0, 0, 0, 0.4) 100%) !default;

.chGrid {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(md){
    padding-left: 20px;
    padding-right: 20px;
  }
}

@mixin chGridRow-minus-mobile {
  margin-left: -7px;
  margin-right: -7px;
}

@mixin chGridRow-minus-large {
  margin-left: -10px;
  margin-right: -10px;
}
@mixin chGrid-item-mobile {
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 14px;
}
@mixin chGrid-item-larget {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.chGrid__row1,
.chGrid__row2,
.chGrid__row3 {
  display: flex;
  flex-wrap: wrap;
  @include chGridRow-minus-mobile;
  @include media-breakpoint-up(lg){
    @include chGridRow-minus-large;
  }
}

.chGrid__item1 {
  flex: 0 0 100%;
  max-width: 100%;
  @include chGrid-item-mobile;
  @include media-breakpoint-up(lg){
    flex: 0 0 60%;
    max-width: 60%;
    @include chGrid-item-larget;
  }
}

.chGrid__item2,
.chGrid__item3 {
  flex: 0 0 50%;
  max-width: 50%;
  @include chGrid-item-mobile;
  @include media-breakpoint-up(lg){
    flex: 0 0 20%;
    max-width: 20%;
    @include chGrid-item-larget;
  }
}

.chGrid__item4,
.chGrid__item5 {
  flex: 0 0 100%;
  max-width: 100%;
  @include chGrid-item-mobile;
  @include media-breakpoint-up(lg){
    flex: 0 0 30%;
    max-width: 30%;
    @include chGrid-item-larget;
  }
}

.chGrid__item6 {
  flex: 0 0 100%;
  max-width: 100%;
  @include chGrid-item-mobile;
  @include media-breakpoint-up(lg){
    flex: 0 0 40%;
    max-width: 40%;
    @include chGrid-item-larget;
    margin-top: -186px;
  }
}

.chGrid__item7 {
  flex: 0 0 100%;
  max-width: 100%;
  @include chGrid-item-mobile;
  display: flex;
  @include media-breakpoint-up(lg){
    flex: 0 0 30%;
    max-width: 30%;
    @include chGrid-item-larget;
    display: block;
    margin-top: -124px;
  }
}

.chGrid__item7__inner {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 7px;
  @include media-breakpoint-up(lg){
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
  }
}

.chGrid__item8__inner {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 7px;
  @include media-breakpoint-up(lg){
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }
}

.chGrid__item9 {
  flex: 0 0 100%;
  max-width: 100%;
  @include chGrid-item-mobile;
  @include media-breakpoint-up(lg){
    flex: 0 0 70%;
    max-width: 70%;
    @include chGrid-item-larget;
  }
}


//////////////////////////////
// CARD 
/////////////////////////////

.chGridCardLink {
  position: relative;
  display: block;
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}


.chGridCard1 {
  height: 306px;
  @include media-breakpoint-up(md){
    height: 488px;
  }
  @include media-breakpoint-up(lg){
    height: 514px;
  }
}

.chGridCard2,
.chGridCard3 {
  height: 245px;
  @include media-breakpoint-up(md){
    height: 328px;
  }
}

.chGridCard4 {
  height: 306px;
  @include media-breakpoint-up(md){
    height: 488px;
  }
  @include media-breakpoint-up(lg){
    height: 288px;
  }
}

.chGridCard5 {
  height: 346px;
  @include media-breakpoint-up(md){
    height: 412px;
  }
}

.chGridCard6 {
  height: 306px;
  @include media-breakpoint-up(md){
    height: 488px;
  }
  @include media-breakpoint-up(lg){
    height: 598px;
  }
}

.chGridCard7 {
  height: 246px;
  @include media-breakpoint-up(md){
    height: 336px;
  }
}


.chGridCard8 {
  height: 246px;
  @include media-breakpoint-up(md){
    height: 336px;
  }
  @include media-breakpoint-up(lg){
    height: 256px;
  }
}

.chGridCard9 {
  height: 306px;
  @include media-breakpoint-up(md){
    height: 488px;
  }
}

// .chGridCard__pad {
  
// }

.chGridCard__overlay {
  position: relative;
  border-radius: 0;
  overflow: hidden;
  @include media-breakpoint-up(md){
    border-radius: 20px;
  }
  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gridImgGradient;
    z-index: 1;
  }
}

.chGridCard__darker {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#000000, 0.6);
  z-index: 2;
}

.chGrid__ArticleImg,
.chGrid__ArticleImgOverlay {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  @include media-breakpoint-up(md){
    border-radius: 20px;
  }
}

.chGrid__ArticleImgOverlay {
  object-position: center center;
  mix-blend-mode: multiply;
}

@supports (-ms-ime-align:auto) {
  .chGrid__ArticleImgOverlay{
      opacity: .6;
  }
}

.chGrid__content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: flex-end;
  align-items: stretch;
  z-index: 1;
}

.chGrid__content__inner {
  position: relative;
  padding: 20px;
  @include media-breakpoint-up(md){
    padding: 25px;
  }
}

.chGrid__title {
  position: relative;
  margin-bottom: 0;
  color: $white;
  text-align: center;
  @include font-medium;
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1.27272;
  letter-spacing: 0.2em;
  @include media-breakpoint-up(md){
    font-size: 1.2rem;
    line-height: 15px;
  }
}

.chGrid__title--alt {
  text-align: left;
  padding-left: 27px;
  @include media-breakpoint-up(lg){
    padding-left: 52px;
  }
}

.chGrid__title__altFor4,
.chGrid__title__altFor5 {
  text-align: left;
  padding-left: 27px;
  @include media-breakpoint-up(lg){
    padding-left: 0;
    text-align: center;
  }
}


.chGrid__subtitle {
  padding-top: 12px;
  padding-bottom: 10px;
  @include font-display;
  font-size: 6.4rem;
  line-height: 1.0357;
  color: $white;
  transform: rotate(-3.22deg);
  @include media-breakpoint-up(lg){
    font-size: 11.2rem;
    
  }
}

.chGrid__subtitle--alt {
  padding-bottom: 10px;
  transform: rotate(-3.22deg);
  @include media-breakpoint-up(lg){
    padding-bottom: 0;
    transform: none;
    padding-top: 20px;
    font-size: 8rem;
    text-align: center;
  }
}

.chGrid__subtitle__altFor1 {
  @include media-breakpoint-up(lg){
    font-size: 6.4rem;
  }
  @include media-breakpoint-up(xl){
    font-size: 11.2rem;
  }
}


.chGridCTA__wrapper {
  height: 100%;
  padding-left: 14px;
  padding-right: 14px;
  @include media-breakpoint-up(md){
    padding-left: 0;
    padding-right: 0;
  }
}

.chGridCTA {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  height: 100%;
  background-color: $ywca-pink;
  background-image: url('../img/grid-card-logo-ywca.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 82px 100px;
  border-radius: 10px;
  @include media-breakpoint-up(md){
    background-size: 146px 180px;
    border-radius: 20px;
  }
  @include media-breakpoint-up(lg){
    background-position: right top;
  }
  @include media-breakpoint-up(xl){
    background-size: 162px 200px;
  }
}

.chGridCTA__content {
  padding: 20px;
  @include media-breakpoint-up(xl){
    padding: 40px;
  }
}

.chGridCTA__heading {
  color: $white;
  margin-bottom: 14px;
  padding-right: 80px;
  @include media-breakpoint-up(md){
    margin-bottom: 20px;
    padding-right: 112px;
  }

  @include media-breakpoint-up(lg){
    padding-right: 54px;
  }
}

.chGridCTA__btn {
  color: $white;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  @include font-medium;
  padding: 1.25rem 2.8rem;
}