.chWrapper {
  padding-bottom: 54px;
  background-color: $off-white;
  background-image: url(../img/content-hub-bg-graphic.png);
  background-position: top 44px center;
  background-size: 364px 354px;
  background-repeat: no-repeat;
  overflow-x: hidden;
  // background: $off-white url(../img/content-hub-bg-graphic.png) no-repeat top 44px center/488px 475px;
  @include media-breakpoint-up(md){
    background-position: top 34px center;
    background-size: 488px 475px;
    padding-bottom: 74px;
  }
}

.chTitle {
  padding-top: 62px;
  padding-bottom: 32px;
  text-align: center;
}

.chTitle__title {
  display: inline-block;
  text-align: center;
  color: $ywca-pink;
  transform: rotate(-1.43deg);
  @include media-breakpoint-up(md){
    font-size: 11.2rem;
    line-height: 1.09821;
  }
  @include media-breakpoint-up(lg){
    font-size: 25rem;
    line-height: 1.092‬;
  }
}

.chFilterSelector {
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 30px;
  @include media-breakpoint-up(lg){
    display: none;
  }
}

.chFilterSelector__btn {
  display: block;
  width: 100%;
  @include font-bold;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.chFilterGroup__wrapper {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  display: none;
  @include media-breakpoint-up(lg){
    display: block;
  }
}

.chFilterGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-left: -20px;
}

.chFilterGroup__btn {
  margin-top: 20px;
  margin-left: 20px;

  &.is-active {
    background-color: $ywca-pink;
    border-color: $ywca-pink;
    color: $white;
  }
}

//////////////////////////////
// MODAL 
/////////////////////////////

body.chModal--open {
  overflow: hidden;
  .chModal__backdrop {
    display: block;
  }
  .chModal {
    display: block;
  }
}

.chModal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: $dark-purple;
  opacity: .7;
  display: none;
}

.chModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1072;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.chModal__dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none;
  @include media-breakpoint-up(md){
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

.chModal__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 10px;
  outline: 0;
}

.chModal__header {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.chModal__header__title {
 @include font-medium;
 font-size: 1.2rem;
 line-height: 1.16666;
 letter-spacing: 0.2em;
 text-transform: uppercase;
 color: $ywca-purple;
}

.chModal__header__btnClose {
  margin-left: auto;
  margin-right: -15px;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: $ywca-purple;
  width: 50px;
  height: 50px;

  &:focus,
  &:hover {
    outline: none;
  }
}

.chModal__body {
  padding: 20px 20px 30px 20px;
}

.chModal__footer {
  text-align: center;
  padding-bottom: 27px;
}

.filter-checkbox + .filter-checkbox {
  margin-top: 2.4rem;
}

.filter-checkbox .custom-control-label {
  font-size: 2rem;
  line-height: 1.2;
}

.filter-checkbox .custom-control-label:before{
  border-radius: 50%;
  background-color: #DFDFDF;
  border-color: #DFDFDF;
}

.filter-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border-color: #DFDFDF;
}

.filter-checkbox .custom-control-input:checked ~ .custom-control-label {
  @include font-bold;
}

.filter-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $ywca-pink;
  background-color: $ywca-pink;
}

.filter-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}


.chGridLoader {
  text-align: center;
  img {
    width: 94px;
    height: 94px;
    user-select: none;
  }
}