.heroBlock {

}

.heroBlock__wrapper {
  position: relative;
  z-index: 1;
}

.heroBlock__container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0fr;
  grid-template-areas: "heroMedia" "heroContent";

  @include media-breakpoint-up(lg){
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "heroContent heroMedia";
  }
}

.heroMedia { 
  grid-area: heroMedia; 
  position: relative;
  min-height: 50vh;
  overflow: hidden;
  @include media-breakpoint-up(lg){
    height: calc(100vh - 120px);
  }

  @include media-breakpoint-up(xl){
    height: calc(100vh - 74px);
  }

  &.hero-media__higher {
    @include media-breakpoint-up(lg){
      height: 100vh;
    }
  
    @include media-breakpoint-up(xl){
      height: 100vh;
    }
  }

  &.hero-media__higherAlt {
    @include media-breakpoint-up(lg){
      height: calc(100vh - 132px);
    }
  
    @include media-breakpoint-up(xl){
      height: calc(100vh - 120px);
    }
  }
}

.heroContent { 
  grid-area: heroContent;
  align-items: center;
}

.heroContent__image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.heroMedia__video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 101%;
  min-height: 101%;
  width: auto;
  height: auto;
}

.heroContent__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-left: 44px;
  padding-right: 44px;
  padding-bottom: 48px;
  background-color: $white;
  @include media-breakpoint-up(md){
    padding-top: 244px;
    padding-left: 144px;
    padding-right: 144px;
    padding-bottom: 44px;
  }
  @include media-breakpoint-up(lg){
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    justify-content: center;
  }
  @include media-breakpoint-up(xl){
    padding-right: 260px;
  }
}

.heroScrollForMore {
  position: absolute;
  left: 72px;
  bottom: 0;
  height: 64px;
  z-index: 1;
  border-left: 1px solid $white;
  visibility: hidden;
  @include media-breakpoint-up(lg){
    visibility: visible;
  }

  span {
    padding-left: 28px;
    position: relative;
    top: -2px;
    display: block;
    color: $white;
    font-size: 1rem;
    line-height: 1.3;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
}

.heroContentGraphic__y {
  position: absolute;
  top: 0%;
  left: 50%;
  width: 165px;
  height: 160px;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  mix-blend-mode: multiply;

  @include media-breakpoint-up(md){
    width: 311px;
    height: 302px;
  }

  @include media-breakpoint-up(lg){
    display: none;
  }
}

.heroRTE {
  p {
    @include heading1;
  }
}

.heroContent__CTA {
  margin-top: 36px;
  @include media-breakpoint-up(lg){
    margin-top: 62px;
  }
  @include media-breakpoint-up(xl){
    margin-top: 74px;
  }
}

