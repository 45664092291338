body {
  // padding-top: 74px;
}

.block-top-small {
  padding-top: 30px;
  @include media-breakpoint-up(md){
    padding-top: 50px;
  }
}
.block-top-medium {
  padding-top: 60px;
  @include media-breakpoint-up(md){
    padding-top: 100px;
  }
}
.block-top-large {
  padding-top: 120px;
  @include media-breakpoint-up(md){
    padding-top: 220px;
  }
}

.block-bottom-small {
  padding-bottom: 30px;
  @include media-breakpoint-up(md){
    padding-bottom: 50px;
  }
}
.block-bottom-medium {
  padding-bottom: 60px;
  @include media-breakpoint-up(md){
    padding-bottom: 100px;
  }
}
.block-bottom-large {
  padding-bottom: 30px;
  @include media-breakpoint-up(md){
    padding-bottom: 50px;
  }
}