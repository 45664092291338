.contactUsPage {
  background-color: $off-white;
  padding: 50px 0 50px;

  @include media-breakpoint-up(md){
    padding-bottom: 92px;
  }
}

.contact-container {
  max-width: 1320px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 0fr 0fr 0fr 0fr;
  grid-template-areas: 
    "." "." "." "."
    "contactSeparator" 
    "contactNational";

  @include media-breakpoint-up(lg){
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 0fr 0fr 0fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-areas: 
      ". . . ." 
      "contactSeparator contactSeparator contactSeparator contactSeparator" 
      "contactNational contactNational contactNational .";
  }  
}

.contactNational { 
  grid-area: contactNational; 
}

.contactSeparator { 
  grid-area: contactSeparator; 
  display: none;
  @include media-breakpoint-up(lg){
    display: block;
  }
}

.contact-item.contact-item--purple {
  .contactAccordion__heading,
  .contactAccordion__caption {
    color: $ywca-purple;
  }
}

.contact-item.contact-item--blue {
  .contactAccordion__heading,
  .contactAccordion__caption {
    color: $ywca-blue;
  }
}

.contact-item.contact-item--green {
  .contactAccordion__heading,
  .contactAccordion__caption {
    color: $ywca-green;
  }
}

.contact-item.contact-item--pink {
  .contactAccordion__heading,
  .contactAccordion__caption {
    color: $ywca-pink;
  }
}

.contact-item.contact-item--dark {
  .contactAccordion__heading,
  .contactAccordion__caption {
    color: $dark-purple;
  }
}

.contact-item.contact-item--yellow {
  .contactAccordion__heading,
  .contactAccordion__caption {
    color: $ywca-yellow;
  }
}

.contactAccordion {
  position: relative;
  padding-top: 18px;
}

.contactAccordion__heading {
  display: flex;
  align-items: center;
  padding-bottom: 18px;
  user-select: none;
  &.is-active {
    position: relative;

    .contactAccordion__icon svg{
      transform: rotate(-180deg);
    }
  }
}

.contactAccordion__icon {
  margin-left: auto;
  @include media-breakpoint-up(lg){
    display: none;
  }
}

.contactSeparator__hr {
  max-width: 996px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 40px;
  hr {
    border-top-color: $black;
    opacity: .1;
  }
}

.contactAccordion__collapse {
  list-style: none;
  padding: 10px 0 0 0;
  margin: 0;
  display: none;
  @include media-breakpoint-up(lg){
    display: block;
  }

  li {
    padding-bottom: 40px;
  }

  &.is-open {
    display: block;
  }
}

.contactAccordion__caption {
  margin-bottom: 15px;
}

.contactRTE {
  a {
    color: $body-color;
  }
  p {
    font-size: 14px;
    line-height: 1.6428;
  }
}

.contactAccordionLast {
  @include media-breakpoint-up(lg){
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .contactAccordion__heading {
    @include media-breakpoint-up(lg){
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }

  .contactAccordion__collapse {
    @include media-breakpoint-up(lg){
      display: flex;
      width: 100%;
      flex: 0 0 auto;
      margin-left: -10px;
    }

    li {
      @include media-breakpoint-up(lg){
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding-left: 20px;
      }
    }
  }
}


.contactMapMobile {
  margin-top: 20px;
  position: relative;
  background: $white;
  @include media-breakpoint-up(lg){
    display: none;
  }
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (167 / 375) * 100%;
  }
  > .contactMapMobile__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}


.contactBottomContainer {
  position: relative;
  max-width: 1320px;
  margin-top: 46px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 48px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(lg){
    padding-top: 126px;
  }
}

.contactBottomGraphic {
  display: none;
  position: absolute;
  top: 0;
  left: 41.5%;
  width: 460px;
  user-select: none;
  z-index: 0;
  img {
    user-select: none;
  }
  @include media-breakpoint-up(lg){
    display: block;
  }
}

.contactMapLarge {
  display: none;
  margin-top: 84px;
  position: relative;
  background: $white;
  @include media-breakpoint-up(lg){
    display: block;
  }
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (665 / 522) * 100%;
  }
  > .contactMapLarge__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.contactForm {
  position: relative;
}

.contactForm__title {
  margin-bottom: 38px;
  @include media-breakpoint-up(lg){
    margin-bottom: 34px;
  }
}