.tilesBlock {

}

.tilesBlock__wrapper {
  background-color: $off-white;
}

.tilesBlock__container {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  padding-bottom: 70px;
  @include media-breakpoint-up(lg){
    padding-top: 136px;
    padding-bottom: 136px;
  }
}

.tilesBlock__link {
  display: block;
  padding: 0;
  position: relative;
  margin-bottom: 20px;
  border: none;
  background: transparent;
  overflow: hidden;
  border-radius: 10px;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    .tilesBlock__link__media {
      transform: scale(1.05);
    }
    .tilesBlock__linkIcon {
      transform: scale(1.2);
    }
  }
}

.tilesBlock__link__media {
  border-radius: 10px;
  transform: scale(1);
  transition: transform 0.3s ease;
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000000, 0.2);
  }
}

.tilesBlock__link__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 0fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";
  align-items: flex-end;
}

.tilesBlock__linkTitle {
  color: $white;
  padding-right: 8px;
  .card-title {
    margin-bottom: 6px;
  }
}

.tilesBlock__linkIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: $white;
  color: $ywca-purple;
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.3s ease;
  svg {
    width: 16px;
    height: 16px;
  }
}