.errorPage {
  height: 100vh;

  @include media-breakpoint-up(xl){
    background-image: url(../img/hero-y-graphic-yellow.png);
    background-size: 484px 470px;
    background-position: 90% 50%;
    background-repeat: no-repeat;
  }
}

.errorPage__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.errorPage__container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.errorPage__title {
  color: $ywca-purple;
}

.errorPage__description {
  margin-top: 12px;
  margin-bottom: 32px;
}

.errorPage__logo {
  width: 60px;
  margin-bottom: 24px;
}