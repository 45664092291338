//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

@mixin heading1 {
  @include font-serif;
  font-size: 2.6rem;
  line-height: 1.53846;
  @include media-breakpoint-up(md){
    font-size: 3.5rem;
    line-height: 1.54285;
  }
}

@mixin heading2 {
  @include font-regular;
  font-size: 2rem;
  line-height: 1.5;
}

@mixin heading3 {
  @include font-medium;
  font-size: 1.4rem;
  line-height: 1.2857;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  @include media-breakpoint-up(md){
    font-size: 1rem;
    line-height: 1.3;
  }
}

h1,
.h1 {
  @include heading1;
}

h2,
.h2 {
  @include heading2;
}

h3,
.h3 {
  @include heading3;
}

.h4 {
  @extend h4;
}

.h5 {
  @extend h5;
}

.h6 {
  @extend h6;
}

.lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

// Type display classes
.display-1 {
  @include font-size($display1-size);
  font-weight: $display1-weight;
  line-height: $display-line-height;
}
.display-2 {
  @include font-size($display2-size);
  font-weight: $display2-weight;
  line-height: $display-line-height;
}
.display-3 {
  @include font-size($display3-size);
  font-weight: $display3-weight;
  line-height: $display-line-height;
}
.display-4 {
  @include font-size($display4-size);
  font-weight: $display4-weight;
  line-height: $display-line-height;
}

.card-title {
  @include font-serif;
  font-size: 2.2rem;
  line-height: 2.6rem;
  text-transform: none;
  letter-spacing: 0;
}

.display-font {
  margin-top: 0;
  margin-bottom: 0;
  @include font-display;
  font-size: 11.2rem;
  line-height: 1.09821;
  @include media-breakpoint-up(md){
    font-size: 25rem;;
    line-height: 1.092;
  }
}

p,
.p {
  font-size: 1.6rem;
  line-height: 1.75;
  @include media-breakpoint-up(md){
    line-height: 1.625;
  }
}

//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
  @include font-size($small-font-size);
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size(90%);
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  @include font-size($blockquote-font-size);
}

.blockquote-footer {
  display: block;
  @include font-size($blockquote-small-font-size);
  color: $blockquote-small-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}
