@import url("//hello.myfonts.net/count/3afc8c");

  
@font-face {
  font-family: 'Couturier-SemiBoldIt';
  src: url('../fonts/3AFC8C_0_0.eot');
  src: url('../fonts/3AFC8C_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/3AFC8C_0_0.woff2') format('woff2'),
    url('../fonts/3AFC8C_0_0.woff') format('woff'),
    url('../fonts/3AFC8C_0_0.ttf') format('truetype');
}
 
  
@font-face {
  font-family: 'Couturier-SemiBold';
  src: url('../fonts/3AFC8C_1_0.eot');
  src: url('../fonts/3AFC8C_1_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/3AFC8C_1_0.woff2') format('woff2'),
    url('../fonts/3AFC8C_1_0.woff') format('woff'),
    url('../fonts/3AFC8C_1_0.ttf') format('truetype');
}
 
  
@font-face {
  font-family: 'TTNormsPro-Regular';
  src: url('../fonts/3AFC8C_2_0.eot');
  src: url('../fonts/3AFC8C_2_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/3AFC8C_2_0.woff2') format('woff2'),
    url('../fonts/3AFC8C_2_0.woff') format('woff'),
    url('../fonts/3AFC8C_2_0.ttf') format('truetype');
}
 
  
@font-face {
  font-family: 'TTNormsPro-Medium';
  src: url('../fonts/3AFC8C_3_0.eot');
  src: url('../fonts/3AFC8C_3_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/3AFC8C_3_0.woff2') format('woff2'),
    url('../fonts/3AFC8C_3_0.woff') format('woff'),
    url('../fonts/3AFC8C_3_0.ttf') format('truetype');
}
 
  
@font-face {
  font-family: 'TTNormsPro-Bold';
  src: url('../fonts/3AFC8C_4_0.eot');
  src: url('../fonts/3AFC8C_4_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/3AFC8C_4_0.woff2') format('woff2'),
    url('../fonts/3AFC8C_4_0.woff') format('woff'),
    url('../fonts/3AFC8C_4_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Avallon Alt';
  src: url('../fonts/AvallonAlt.woff2') format('woff2'),
      url('../fonts/AvallonAlt.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@mixin font-regular {
  font-family: 'TTNormsPro-Regular';
  font-weight: normal;
}

@mixin font-medium {
  font-family: 'TTNormsPro-Medium';
  font-weight: normal;
}

@mixin font-bold {
  font-family: 'TTNormsPro-Bold';
  font-weight: normal;
}

@mixin font-serif {
  font-family: 'Couturier-SemiBold';
  font-weight: normal;
}

@mixin font-serif-italic {
  font-family: 'Couturier-SemiBoldIt';
  font-weight: normal;
}

@mixin font-display {
  font-family: 'Avallon Alt';
  font-weight: normal;
  font-style: normal;
}