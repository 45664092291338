.siteHeader {
  width: 100%;
  height: 74px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background: $white;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.07);
  display: none;
  transition: all 0.2s ease;
  @include media-breakpoint-up(xl){
    display: block;
  }
}

.siteHeader__container {
  display: flex;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 1300px) {
    padding-left: 74px;
    padding-right: 74px;
  }
}

.siteHeader__col {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.siteHeader__logo {
  display: block;
  width: 56px;
}

//////////////////////////////
// Location Drop Down 
/////////////////////////////

.siteHeader__locationDropDown {
  position: relative;
  margin-left: auto;
  @include font-bold;
  font-size: 1.3rem;
  line-height: 1.53846;
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.siteHeader__location__Header {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $ywca-purple;
  user-select: none;
  
  span {
    border-bottom: 2px solid transparent; 
  }

  svg {
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin-left: 6px;
    transition: transform 0.2s ease;
  }

  &:hover {
    span {
      border-bottom-color: $ywca-purple;
    }
  }

  &.active {
    span {
      border-bottom-color: $ywca-purple;
    }
    svg {
      transform: rotate(180deg);
    }
  }
}

.siteHeader___locationDropDownNav {
  display: none;
  position: absolute;
  top: 100%;
  left: -24px;
  margin: 0;
  min-width: 165px;
  padding: 12px 0;
  list-style: none;
  background: $white;
  border-radius: 0 0 30px 30px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.05);

  &.active {
    display: block;
  }

  a {
    display: block;
    padding: 6px 24px;
    &:hover {
      text-decoration: none;
    }
  }
}

//////////////////////////////
// Donate Button 
/////////////////////////////

.siteHeader__donate {
  margin-left: auto;
  padding-left: 20px;
}

.siteHeader__btnDonate {
  @include font-bold;
  padding: 0rem 2.8rem;
  line-height: 32px;
  color: $white;
  background-color: $ywca-green;
  transition: all 0.4s cubic-bezier(.84,0,.23,1); 
  transition-delay: 0.1s;

  &:hover {
    color: $white;
    background-color: darken($ywca-green, 10%);
  }
}


//////////////////////////////
// SiteHeader Navigation 
/////////////////////////////

.siteHeader__nav {
  position: relative;
}

.siteHeader__navigation {
  list-style: none;
  padding: 0 0 0 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    padding-left: 20px;

    @media (min-width: 1300px) {
      padding-left: 30px;
    }
  }

  a {
    @include font-bold;
    color: $ywca-purple;
    font-size: 1.3rem;
    display: block;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;

    &:hover {
      color: $ywca-purple;
      text-decoration: none;
      border-bottom-color: $ywca-purple;
    }

    &.active {
      color: $ywca-purple;
      border-bottom-color: $ywca-purple;
    }
  }
}



//////////////////////////////
// Navigation transparent 
/////////////////////////////

.siteHeader.nav-transparent {
  position: fixed;
  background: transparent;
  box-shadow: none;
  height: 82px;
  padding-top: 8px;
  transform: translateY(0px);

  .siteHeader__navigation {
    a {
      color: $white;
      &:hover,
      &.active {
        border-bottom-color: $white;
      }
    }
  }

  .siteHeader__btnDonate {
    padding: 1.25rem 4rem;
    line-height: 1.84615;
    background-color: $white;
    color: $ywca-purple;

    &:hover {
      color: $white;
      background-color: darken($ywca-green, 10%);
    }
  }
}

//////////////////////////////
// SiteHeader Scrolled with transparent 
/////////////////////////////

.siteHeader.nav-transparent.siteHeader--scrolled {
  padding-top: 0;
  height: 74px;
  position: sticky;
  background: $white;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.07);
  transition: all 0.3s cubic-bezier(.84,0,.23,1);

  .siteHeader__navigation {
    a {
      color: $ywca-purple;
      &:hover,
      &.active {
        border-bottom-color: $ywca-purple;
      }
    }
  }

  .siteHeader__btnDonate {
    padding: 0rem 2.8rem;
    line-height: 32px;
    color: $white;
    background-color: $ywca-green;
    transition: all 0.4s cubic-bezier(.84,0,.23,1); 
    transition-delay: 0.1s;

    &:hover {
      color: $white;
      background-color: darken($ywca-green, 10%);
    }
  }
}