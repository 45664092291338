.newsBlock {
  
}

.newsBlock__wrapper {
  background-color: $off-white;
}

.newsBlock__container {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  padding-bottom: 70px;
  @include media-breakpoint-up(lg){
    padding-top: 136px;
    padding-bottom: 136px;
  }
}

.newsBlock__carousel {
  // overflow-y: hidden;
  // overflow-x: visible;
  max-width: 1420px;
  padding-left: 20px;
  padding-right: 20px;
  @include media-breakpoint-up(md){
    padding-left: 70px;
    padding-right: 70px;
  }
  .swiper-wrapper {
    @include media-breakpoint-up(xl){
      justify-content: center;
      padding-left: 10px;
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 302px;
    @include media-breakpoint-up(sm){
      width: auto;
    }
  }
}

.newsCard {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
  border-radius: 20px;
}

.newsCard__img {
  position: relative;
  flex: 0 0 auto;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000000, 0.2);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.newsCard__title {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .card-title {
    color: $white;
    @include media-breakpoint-up(xl){
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
    @media (min-width: 1300px) {
      font-size: 2.2rem;
    }
  }
}

.newsCard__summary {
  color: $lighter-font-color;
}

.newsCard__content {
  flex: 1 1 auto;
  padding: 20px 24px 0 24px;
}

.newsCard__tagsGroup {
  list-style: none;
  margin: -4px 0 0 0;
  padding: 0 0 14px 0;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.newsCard__tags-item {
  display: inline-block;
  margin-right: 4px;
  margin-top: 4px;
  padding-left: 12px;
  padding-right: 12px;
  @include font-medium;
  color: $ywca-purple;
  border: 1px solid $ywca-purple;
  font-size: 1.4rem;
  line-height: 1.7142;
  border-radius: 5px;
  user-select: none;
}

.newsCard__cta {
  flex: 0 0 auto;
  padding: 21px 30px;
  text-align: right;
}

.newsCard__link {
  color: $ywca-purple;
  display: inline-flex;
  align-items: center;

  &:hover,
  &:focus {
    text-decoration: none;

    .newsCard__linkText {
      padding-right: 30px;
    }

    .newsCard__linkIcons {
      transform: scale(1.1);
    }
  }
}

.newsCard__linkText {
  padding-right: 24px;
  transition: padding 0.2s ease;
  font-size: 13px;
  line-height: 2;
}

.newsCard__linkIcons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: $ywca-purple;
  border-radius: 50%;
  color: $white;
  transition: transform 0.2s ease;
  
  svg {
    width: 16px;
    height: 16px;
  }
}


//////////////////////////////
// THEME COLOR FOR LINK 
/////////////////////////////

.newsCard__link.newsCard__link--purple {
  color: $ywca-purple;
  .newsCard__linkIcons {
    background-color: $ywca-purple;
    &:before {
      border-color: $ywca-purple;
    }
  }
  &:hover,
  &:focus {
    color: darken($ywca-purple, 10%);
    .newsCard__linkIcons {
      background-color: darken($ywca-purple, 10%);
    }
  }
}

.newsCard__link.newsCard__link--pink {
  color: $ywca-pink;
  .newsCard__linkIcons {
    background-color: $ywca-pink;
  }
  &:hover,
  &:focus {
    color: darken($ywca-pink, 10%);
    .newsCard__linkIcons {
      background-color: darken($ywca-pink, 10%);
    }
  }
}

.newsCard__link.newsCard__link--blue {
  color: $ywca-blue;
  .newsCard__linkIcons {
    background-color: $ywca-blue;
  }
  &:hover,
  &:focus {
    color: darken($ywca-blue, 10%);
    .newsCard__linkIcons {
      background-color: darken($ywca-blue, 10%);
    }
  }
}

.newsCard__link.newsCard__link--green {
  color: $ywca-green;
  .newsCard__linkIcons {
    background-color: $ywca-green;
  }
  &:hover,
  &:focus {
    color: darken($ywca-green, 10%);
    .newsCard__linkIcons {
      background-color: darken($ywca-green, 10%);
    }
  }
}

.newsCard__link.newsCard__link--yellow {
  color: $ywca-yellow;
  .newsCard__linkIcons {
    background-color: $ywca-yellow;
  }
  &:hover,
  &:focus {
    color: darken($ywca-yellow, 10%);
    .newsCard__linkIcons {
      background-color: darken($ywca-yellow, 10%);
    }
  }
}