//////////////////////////////
// News Hero 
/////////////////////////////

.newsHero {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  @include media-breakpoint-up(md){
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }
  @include media-breakpoint-up(xl){
    max-width: 1320px;
  }
}

.newsHero__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  @include media-breakpoint-up(lg){
    margin-right: -10px;
    margin-left: -10px;
  }
}

.newsHero__colImage {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  @include media-breakpoint-up(lg){
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.newsHero__colContent {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  @include media-breakpoint-up(lg){
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.newsHero__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 40px;
  @include media-breakpoint-up(lg){
    padding-left: 82px;
    justify-content: space-between;
  }
}

.newsHero__header {
  position: relative;
  max-width: 508px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  @include media-breakpoint-up(lg){
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.newsHero__header__subtitle {
  margin-bottom: 15px;
  color: $ywca-purple;
  @include media-breakpoint-up(lg){
    margin-bottom: 20px;
  }
}

.newsHero__label {
  margin-bottom: 8px;
}

.newsLabel__groups {
  list-style: none;
  margin: -4px 0 0 0;
  padding: 0 0 16px 0;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  @include media-breakpoint-up(lg){
    padding-bottom: 22px;
  }
}

.newsLabel-item {
  display: inline-block;
  margin-right: 4px;
  margin-top: 4px;
  padding-left: 12px;
  padding-right: 12px;
  @include font-medium;
  color: $ywca-purple;
  border: 1px solid $ywca-purple;
  font-size: 1.4rem;
  line-height: 1.7142;
  border-radius: 5px;
  user-select: none;
}

.newsHero__author {
  position: relative;
  display: flex;
  align-items: center;
  padding: 18px 0;
  max-width: 100%;
  background-color: $off-white;
  margin-left: 24px;
  @include media-breakpoint-up(lg){
    max-width: 468px;
    margin-left: 0;
    margin-right: 40px;
  }
}

.newsHero__author__colFirst {
  flex: 0 0 68px;
  max-width: 68px;
  border-radius: 50%;
  margin-left: -24px;
}

.newsHero__author__colLast {
  padding-left: 36px;
}

.newsHero__author__img {
  border-radius: 50%;
}

.newsHero__author__date {
  display: block;
  color: $ywca-purple;
  margin-bottom: 13px;
}

.newsHero__author__name {
  display: block;
}


//////////////////////////////
// News CONTENT 
/////////////////////////////

.newsContentWrapper {
  padding: 130px 20px 78px 20px;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(md){
    padding-top: 144px;
    padding-bottom: 126px;
  }
}

.newsContentWrapper__container {
  @include media-breakpoint-up(md){
    padding-left: 100px;
  }

  @include media-breakpoint-up(lg){
    padding-left: 224px;
  }
}

.newsContentWrapper__container__inner {
  max-width: 616px;
}

.newsSMShare {
  margin-top: 40px;
}

.newsSMShare__heading {
  color: $ywca-purple;
  margin-bottom: 32px;
  text-align: center;
  @include media-breakpoint-up(md){
    text-align: left;
    margin-bottom: 22px;
  }
}

.newsSMShare__group {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  @include media-breakpoint-up(md){
    max-width: 258px;
  }
}

.newsSMShare__item {
  color: $ywca-purple;
  svg {
    width: 34px;
    height: 34px;
    vertical-align: top;
  }

  &:hover,
  &:focus {
    outline: none;
    color: darken($ywca-purple, 10%);
  }
}


//////////////////////////////
// RTE  
/////////////////////////////

.newsRTE {

  h1 {
    text-align: center;
    margin-bottom: 47px;
    @include media-breakpoint-up(md){
      margin-bottom: 40px;
      text-align: left;
    }
  }
  h2 {
    margin-bottom: 24px;
  }
  h3 {
    margin-bottom: 26px;
    @include media-breakpoint-up(md){
      margin-bottom: 30px;
    }
  }

  ul,
  ol,
  p {
    margin-bottom: 24px;
  }

  ul,
  ol {
    font-size: 1.6rem;
    line-height: 1.75;
    @include media-breakpoint-up(md){
      line-height: 1.625;
    }
  }

  .blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
    @include font-display;
    font-size: 4.6rem;
    line-height: 1.021;
    color: $ywca-purple;
    text-align: center;

    @include media-breakpoint-up(md){
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 6.6rem;
      line-height: 0.9696;
    }
  }

  img {
    margin-top: 16px;
    margin-bottom: 16px;
    @include media-breakpoint-up(lg){
      max-width: calc(100% + 6.4vw);
    }
  }

  
}

