
.graphic-y-Behind {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-image: url(../img/hero-y-graphic-white.png);
  background-size: 380px 369px;
  background-position: 50% 60%;
  background-repeat: no-repeat;
  pointer-events: none;
  display: none;
  @include media-breakpoint-up(xl){
    display: block;
  }
  @media (min-width: 1300px) {
    background-size: 484px 470px;
  }
}

.graphic-y-Front {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  mix-blend-mode: multiply;
  display: none;
  will-change: opacity;
  transition: opacity 0s linear;
  @include media-breakpoint-up(xl){
    display: block;
  }
}

.graphic-y-Front__inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-image: url(../img/hero-y-graphic-purple.png);
  background-size: 380px 369px;
  background-position: 50% 60%;
  background-repeat: no-repeat;
  pointer-events: none;
  @media (min-width: 1300px) {
    background-size: 484px 470px;
  }
}

@supports (-ms-ime-align:auto) {
  .graphic-y-Front__inner {
      opacity: .6;
  }
  .graphic-y-Behind {
    display: none;
  }
}