.formBlock {

}

.formBlock__wrapper {

}

.formBlock__container {
  padding-top: 100px;
  padding-bottom: 100px;
  .container {
    max-width: 980px;
  }
}

.form-page + .form-page {
  margin-top: 40px;
}

.form-page,
.form-receipt {
  h2 {
    @include heading1;
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 30px;
  }
}


.formKjac.ng-submitted {

  input[type="text"].ng-invalid,
  input[type="email"].ng-invalid,
  input[type="date"].ng-invalid,
  input[type="tel"].ng-invalid,
  input[type="number"].ng-invalid,
  textarea.ng-invalid,
  select.ng-invalid {
    border-color: $red;
  }
}