.siteFooter {
  position: relative;
  background-color: $ywca-purple;
}

.siteFooter__wrapper {
  padding-top: 40px;
  padding-bottom: 14px;
  @include media-breakpoint-up(md){
    padding-top: 26px;
    padding-bottom: 42px;
  }
  @include media-breakpoint-up(lg){
    padding-top: 38px;
    padding-bottom: 90px;
  }
}

