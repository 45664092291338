.secondaryNav {
  width: 100%;
  height: 72px;
  background-color: $ywca-green;

  @include media-breakpoint-up(xl){
    height: 46px;
  }
}

.secondaryNav__lg {
  height: 100%;
  display: flex;
  align-items: center;
  display: none;
  padding-left: 20px;
  padding-right: 20px;
  @include media-breakpoint-up(xl){
    display: flex;
  }
  @media (min-width: 1300px){
    padding-left: 74px;
    padding-right: 74px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li + li {
    padding-left: 20px;

    @media (min-width: 1300px){
      padding-left: 30px;
    }
  }

  a {
    display: block;
    @include font-bold;
    font-size: 1.3rem;
    color: $white;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;

    &:hover,
    &.active {
      text-decoration: none;
      border-bottom-color: $white;
    }
  }
}

.secondaryNav__sm {
  position: relative;
}

.secondaryNav__sm__heading {
  display: flex;
  align-items: center;
  @include font-bold;
  font-size: 1.3rem;
  color: $white;
  height: 72px;
  padding-left: 50px;
  padding-right: 30px;
  cursor: pointer;

  span.close {
    position: relative;
    top: -3px;
    margin-left: auto;
    width: 16px;
    height: 16px;
    border-top: 2px solid $white;
    border-left: 2px solid $white;
    transform: rotate(-135deg);
    transition: all 0.2s ease;
  }

  &.active {
    span.close {
      top: 0;
      transform: rotate(-315deg);
    }
  }
}

.secondaryNav__sm__dropDown {
  display: none;
  list-style: none;
  padding: 30px 0 30px 0;
  margin: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 5;
  background-color: $ywca-green;

  li {
    padding-left: 50px;
    padding-right: 20px;
  }

  a {
    padding-top: 15px;
    padding-bottom: 15px;
    display: block;
    @include font-bold;
    font-size: 1.3rem;
    color: $white;

    &:hover {
      text-decoration: none;
    }
  }
}