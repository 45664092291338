.siteHeaderMobile {
  width: 100%;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background: $white;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.07);

  @include media-breakpoint-up(xl){
    display: none;
  }
}

.siteHeaderMobile__container {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.siteHeaderMobile__logo {
  width: 32px;
}

.siteHeaderMobile__burger {
  position: relative;
  margin-left: auto;
  background-color: transparent;
  border: none;
  width: 38px;
  height: 20px;
  padding: 0;
  display: block;

  &:focus {
    outline: 0;
  }

  .line {
    position: relative;
    display: block;
    width: 38px;
    height: 2px;
    background: $ywca-purple;

    &:before,
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      width: 38px;
      height: 2px;
      background: $ywca-purple;
    }

    &:before {
      top: -9px;
    }

    &:after {
      top: 9px;
    }
  }
}


//////////////////////////////
// Site Mobile navigation 
/////////////////////////////

.siteMobile {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 12;
  background: $white;
  transform: translateY(0) translateX(0);

  &.active {
    display: block;
  }

  @include media-breakpoint-up(xl){
    display: none;
  }
}

.siteMobile__containerTop {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  background: $white;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.07);
}

.siteMobile__containerBottom {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  overflow-y: scroll;
  padding-left: 70px;
  padding-right: 70px;
}

.siteHeaderMobile__close {
  position: relative;
  margin-left: auto;
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  display: block;
  color: $ywca-purple;

  &:focus {
    outline: 0;
  }
}

.siteMobile__nav {
  flex: 0 0 auto;
  list-style: none;
  padding: 12px 0 0 0;
  margin: 0;
}

.siteMobile__nav__item {
  padding-top: 24px;
  padding-bottom: 24px;
  a {
    display: block;
    @include font-regular;
    font-size: 1.8rem;
    line-height: 1.11111;
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.active {
      @include font-medium;
    }
  }
}

.siteMobile__nav__item + .siteMobile__nav__item {
  border-top: 1px solid rgba($black, 0.05);
}

.siteMobile__cta {
  flex: 0 0 auto;
  padding-top: 93px;
  padding-bottom: 72px;
}

.siteMobile__btnDonate {
  @include font-bold;
}

.siteMobile__location {
  position: relative;
  user-select: none;
}

.siteMobile__location__header {
  @include font-medium;
  font-size: 1.8rem;
  line-height: 1.11111;
  color: $ywca-purple;

  svg {
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin-left: 6px;
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.siteMobile__location__items {
  list-style: none;
  padding: 12px 0 0 0;
  margin: 0;
  display: none;

  li {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  a {
    @include font-medium;
    font-size: 1.8rem;
    line-height: 1.11111;

    &:hover {
      text-decoration: none;
    }
  }

  &.active {
    display: block;
  }
}