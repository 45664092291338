.blockHeader__container {
  max-width: 996px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(md){
    padding-bottom: 70px;
  }
  @include media-breakpoint-up(lg){
    padding-bottom: 50px;
  }
}

.blockHeader__RTE {
  text-align: center;

  p {
    @include heading1;
  }
}