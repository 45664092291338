.rteBlock {

}

.rteBlock__wrapper {
  background-color: $off-white;
}

.rteBlock__container {
  position: relative;
  max-width: 1320px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 52px;
  padding-bottom: 42px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;

  @include media-breakpoint-up(md){
    padding-top: 120px;
    padding-bottom: 96px;
  }
}

.rteBlockContainer {
  @include media-breakpoint-up(md){
    padding-left: 100px;
  }

  @include media-breakpoint-up(lg){
    padding-left: 224px;
  }
}

.rteBlockContainer__inner {
  max-width: 616px;
}

.rteBlock__content {
  h1 {
    text-align: center;
    margin-bottom: 47px;
    @include media-breakpoint-up(md){
      margin-bottom: 40px;
      text-align: left;
    }
  }
  h2 {
    margin-bottom: 24px;
  }
  h3 {
    margin-bottom: 26px;
    @include media-breakpoint-up(md){
      margin-bottom: 30px;
    }
  }

  ul,
  ol,
  p {
    margin-bottom: 24px;
  }

  ul,
  ol {
    font-size: 1.6rem;
    line-height: 1.75;
    @include media-breakpoint-up(md){
      line-height: 1.625;
    }
  }

  .blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
    @include font-display;
    font-size: 4.6rem;
    line-height: 1.021;
    color: $ywca-purple;
    text-align: center;

    @include media-breakpoint-up(md){
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 6.6rem;
      line-height: 0.9696;
    }
  }

  img {
    margin-top: 16px;
    margin-bottom: 16px;
    @include media-breakpoint-up(lg){
      max-width: calc(100% + 6.4vw);
    }
  }
}

.rteBlock__cta {
  margin-top: 40px;
}


//////////////////////////////
// Toggle Center 
/////////////////////////////

.rteBlockContainer.rteBlockContainer--center {
  @include media-breakpoint-up(md){
    padding-left: 0;
  }
  @include media-breakpoint-up(lg){
    padding-left: 0;
  }

  .rteBlockContainer__inner {
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
  }

  .rteBlock__content {
    text-align: center;
    img {
      margin-top: 16px;
      margin-bottom: 16px;
      @include media-breakpoint-up(lg){
        max-width: 100%;
      }
    }
  }

  .rteBlock__cta {
    text-align: center;
  }
}