.galleryBlock {

}

.galleryBlock__wrapper {
  background-color: $ywca-pink;
}

.galleryBlock__container {
  position: relative;
  // padding-top: 100px;
  
  // @include media-breakpoint-up(md){
  //   padding-top: 174px;
    
  // }
  // @include media-breakpoint-up(lg){
  //   padding-top: 110px;
    
  // }
}

.galleryBlock__header {
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  z-index: 2;
  overflow: hidden;
  pointer-events: none;
  @include media-breakpoint-up(md){
    top: 24px;
  }
  @include media-breakpoint-up(lg){
    top: 0;
  }
}

.galleryBlock__header__title {
  padding-top: 20px;
  white-space: nowrap;
  text-align: center;
  color: $ywca-purple;
}

.galleryCarousel {
  padding-top: 124px;
  padding-bottom: 102px;
  @include media-breakpoint-up(md){
    padding-top: 174px;
    padding-bottom: 154px;
  }
  @include media-breakpoint-up(lg){
    padding-left: 110px;
    padding-right: 110px;
    padding-bottom: 110px;
    padding-top: 110px;
  }
  @include media-breakpoint-up(xl){
    padding-left: 120px;
    padding-right: 120px;
  }
}

.galleryCarousel__next,
.galleryCarousel__prev {
  top: auto;
  bottom: 20px;
  width: 52px;
  height: 52px;
  color: $white;
  &:after {
    display: none;
  }
  @include media-breakpoint-up(md){
    width: 68px;
    height: 68px;
    bottom: 42px;
  }
  svg {
    width: 52px;
    height: 52px;

    @include media-breakpoint-up(md){
      width: 68px;
      height: 68px;
    }
  }

  @include media-breakpoint-up(lg){
    top: 36px;
    bottom: 0;
    height: 95%;
    width: 84px;

    svg {
      visibility: hidden;
    }
  }

  &.swiper-button-disabled {
    opacity: 0;
  }

  &:focus {
    outline: none;
  }
}

.galleryCarousel__next {
  @include media-breakpoint-up(lg){
    right: 0;
  }
}

.galleryCarousel__prev {
  @include media-breakpoint-up(lg){
    left: 0;
  }
}

.gallery-slide {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (412 / 375) * 100%;

    @include media-breakpoint-up(md){
      padding-top: (347 / 384) * 100%;
    }

    @include media-breakpoint-up(lg){
      padding-top: (341 / 600) * 100%;
    }
  }
}

.gallery-slide__img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery-slide__caption {
  position: absolute;
  color: $white;
  left: 30px;
  right: 30px;
  bottom: 30px;
  @include media-breakpoint-up(md){
    left: 35px;
    right: 35px;
    bottom: 20px;
  }
  @include media-breakpoint-up(lg){
    left: 40px;
    right: 40px;
    bottom: 30px;
  }
}

.gallery-slide__videoBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  background-color: $white;
  transition: transform 0.2s ease;
  color: $ywca-purple;
  border-radius: 50%;

  svg {
    width: 30px;
    height: 30px;
    @include media-breakpoint-up(xl){
      width: 60px;
      height: 60px;
    }
  }

  @include media-breakpoint-up(xl){
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
  }

  &:hover {
    outline: none;
    transform: scale(1.1);
    color: $ywca-purple;
  }
  &:focus {
    outline: none;
  }
}

.gallery-slide__modalBtn {
  display: none;
  position: absolute;
  bottom: -30px;
  right: -30px;
  width: 60px;
  height: 60px;
  background-image: url('../img/icon-expand.png');
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.2s ease;
  border-radius: 50%;
  @include media-breakpoint-up(xl){
    display: block;
  }
  &:hover {
    outline: none;
    transform: scale(1.1);
    color: $ywca-purple;
  }
  &:focus {
    outline: none;
  }
}