.twoColBlock {

}

.twoColBlock__wrapper {

}

.twoColBlock__container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
}

.twoColBlock__ContentRight,
.twoColBlock__mediaLeft,
.twoColBlock__ContentLeft,
.twoColBlock__mediaRight {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  @include media-breakpoint-up(md){
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.twoColBlock__ContentRight {
  padding: 96px 20px 84px 20px;
  @include media-breakpoint-up(md){
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
}

.twoColBlock__ContentLeft {
  padding: 64px 20px 74px 20px;
  @include media-breakpoint-up(md){
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    order: -1;
  }
}

.twoColBlock__mediaLeft {
  @include media-breakpoint-up(md){
    order: -1
  }
}

.twoColBlock__content {
  max-width: 468px;
  margin-left: auto;
  margin-right: auto;
}

.twoColBlock__heading {

}

.twoColBlock__subheading {
  margin-top: 16px;
  @include media-breakpoint-up(md){
    margin-top: 21px;
  }
}

.twoColBlock__CTA {
  margin-top: 40px;
}

.twoColBlock__btnVideo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  background-color: $white;
  transition: transform 0.2s ease;
  color: $ywca-purple;
  border-radius: 50%;

  svg {
    width: 30px;
    height: 30px;
    @include media-breakpoint-up(xl){
      width: 60px;
      height: 60px;
    }
  }

  @include media-breakpoint-up(xl){
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
  }

  &:hover {
    outline: none;
    transform: scale(1.1);
    color: $ywca-purple;
  }
  &:focus {
    outline: none;
  }
}